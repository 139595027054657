import { Trans, useTranslation } from 'react-i18next'
import { Flex, Text, Image, Link } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'
import React from 'react'

import TrevoLogo from '../../../../../../assets/main_logo_mini.svg'

export function TrevoFareUpdatePage() {
  const { t } = useTranslation()

  return (
    <>
      <Flex
        w='100%'
        h={{ sm: '716px', lg: '100%' }}
        direction='column'
        padding={{ sm: '48px 24px', md: '48px 24px', lg: '88px 88px', xl: '88px 88px' }}
        gap={{ sm: '48px', md: '48px', lg: '64px', xl: '64px' }}
        overflow='hidden'
      >
        <Flex direction='column' gap='8px'>
          <Flex gap='16px'>
            <Text fontSize={{ sm: '30px', lg: '36px' }} fontWeight='700' textAlign='left'>
              <Trans i18nKey='promo.banner_fare_update_2025_title_1'>
                Fare updates for <span style={{ color: '#F5A800' }}>2024</span>
              </Trans>
            </Text>
          </Flex>
          <Flex gap='12px'>
            <Text fontSize={{ sm: '16px', lg: '24px' }} fontWeight='600' color='#475467' textAlign='left'>
              <Trans i18nKey='promo.banner_fare_update_2025_title_2'>
                Effective from <span style={{ color: '#F5A800' }}>01/01/2024</span>
              </Trans>
            </Text>
          </Flex>
        </Flex>
        <Flex direction='column' gap='24px'>
          <Flex gap='16px'>
            <Flex bg='#F2F4F7' padding='8px 16px' align='center' w='289px'>
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='700'>
                Bilhete Viagem Urbanas
              </Text>
            </Flex>
            <Flex bg='#F2F4F7' padding='8px 16px' align='center' justify='center'>
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='700' color='#F5A800'>
                1,50€
              </Text>
            </Flex>
          </Flex>
          <Flex gap='16px'>
            <Flex bg='#F2F4F7' padding='8px 16px' align='center' w='289px'>
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='700'>
                Bilhete Diário Linha Azul
              </Text>
            </Flex>
            <Flex bg='#F2F4F7' padding='8px 16px' align='center' justify='center'>
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='700' color='#F5A800'>
                1,20€
              </Text>
            </Flex>
          </Flex>
          <Flex gap='16px'>
            <Flex bg='#F2F4F7' padding='8px 16px' align='center' w='289px'>
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='700'>
                Pré-comprado 10 viagens
              </Text>
            </Flex>
            <Flex bg='#F2F4F7' padding='8px 16px' align='center' justify='center'>
              <Text fontSize={{ sm: '16px', lg: '20px' }} fontWeight='700' color='#F5A800'>
                5,95€
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap='24px' align='center'>
          <Image src={TrevoLogo} w='24px' />
          <Text fontSize={{ sm: '14px', lg: '20px' }} color='#475467'>
            <Trans i18nKey='promo.banner_fare_update_2025_note'>
              The price of passes can be found <Link as={ReactLink} to={'/ticket-fares'} sx={{ textDecoration: 'underline', fontWeight: '600' }}>
                here
              </Link>
              .
            </Trans>
          </Text>
        </Flex>

        <Flex
          position='absolute'
          bottom={{ sm: '-64px', lg: '-32px' }}
          right={{ sm: '-98px', sm2: '-32px' }}
          w={{ sm: '285px !important', sm2: '305px !important', lg: '504px !important' }}
        >
          <Image
            src={TrevoLogo}
            objectFit='contain'
            w={{ sm: '225px !important', sm2: '305px !important', lg: '504px' + ' !important' }}
          />
        </Flex>
      </Flex>
    </>
  )
}
