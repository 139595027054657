import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ChakraProvider } from '@chakra-ui/react'
import { setCustomTheme, setTheme } from '@ubirider/pick-component-library'
import reportWebVitals from './reportWebVitals'
import './i18n'
import theme from './theme/theme'
import '@ubirider/pick-component-library/dist/index.css'
import './index.css'
import config from './config/configLoader'
import ErrorBoundary from './errors/ErrorBoundary'
import * as Sentry from '@sentry/react'

// Sentry Initialization
Sentry.init({
  dsn: 'https://51c8960b238ee5eaca60b629bcb5819d@o4508620565970944.ingest.de.sentry.io/4508620572917840',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/.*\.(proximo|trevo\.com|mobiazores|urbanasbeja)\.pt/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then
  // sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

document.documentElement.style.setProperty('--main-color', config.mainColor)

setTheme('external')
setCustomTheme(config.themeTokens)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ChakraProvider theme={theme}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ChakraProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
