import { useTranslation } from 'react-i18next'
import React from 'react'
import { Button } from '@ubirider/pick-component-library'
import { Flex, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import PickLogo from '../../../../../../assets/pick_logo.svg'
import GoogleLogo from '../../../../../../assets/google_play_black.svg'
import AppleLogo from '../../../../../../assets/apple_black.svg'
import HuaweiLogo from '../../../../../../assets/huawei_black.svg'
import ArrowUpIcon from '../../../../../../assets/arrow_up_purple.svg'

function PickPromoBanner({ showPickPromo, cookiePerms, handlePromoChange }) {
  const { t } = useTranslation()

  if (typeof showPickPromo === 'boolean' ? showPickPromo : showPickPromo === 'true') {
    return (
      <Flex
        direction='column'
        gap={{ sm: '12px', lg: '12px' }}
        padding='16px'
        bg='#F2EEFA'
        border='1px solid #7537FA'
        borderRadius='8px'
        w='100%'
        align={{ sm: 'center', lg: 'center' }}
      >
        <Image src={PickLogo} w='48px' />
        <Flex direction='column' gap='16px' align='center' w='100%'>
          <Text fontSize='16px' fontWeight='700' color='#7537FA' textAlign='center'>
            {t('network_schedule.banner_pick_hub_download')}
          </Text>
          <Flex direction='column' gap='8px' align='center'>
            <Text fontSize='14px' color='#1D2939' display={{ sm: 'none', lg: 'flex' }}>
              {t('network_schedule.download_desktop')}
            </Text>
            <Text fontSize='14px' color='#1D2939' display={{ sm: 'flex', lg: 'none' }}>
              {t('network_schedule.download_mobile')}
            </Text>
            <Flex direction='row' gap='8px'>
              <Link to={'https://play.google.com/store/apps/details?id=ubirider.ubirider'} target={'_blank'}>
                <Flex
                  align='center'
                  justify='center'
                  w='93px'
                  h='48px'
                  bg='white'
                  border='1px solid #D0D5DD'
                  borderRadius='4px'
                >
                  <Image src={GoogleLogo} />
                </Flex>
              </Link>
              <Link to={'https://apps.apple.com/pt/app/pick-hub/id1343817323'} target={'_blank'}>
                <Flex
                  align='center'
                  justify='center'
                  w='93px'
                  h='48px'
                  bg='white'
                  border='1px solid #D0D5DD'
                  borderRadius='4px'
                >
                  <Image src={AppleLogo} />
                </Flex>
              </Link>
              <Link to={'https://appgallery.huawei.com/app/C102997355'} target={'_blank'}>
                <Flex
                  align='center'
                  justify='center'
                  w='93px'
                  h='48px'
                  bg='white'
                  border='1px solid #D0D5DD'
                  borderRadius='4px'
                >
                  <Image src={HuaweiLogo} />
                </Flex>
              </Link>
            </Flex>
          </Flex>
          {cookiePerms === 'all' && (
            <Button
              onClick={() => handlePromoChange(false)}
              label={t('network_schedule.hide')}
              type='neutralDark'
              style='ghost'
            />
          )}
        </Flex>
      </Flex>
    )
  } else {
    return (
      <Flex
        gap='16px'
        padding='16px'
        bg='#F2EEFA'
        align='center'
        justify='space-between'
        border='1px solid #7537FA'
        borderRadius='8px'
        w='100%'
        as='button'
        onClick={() => handlePromoChange(true)}
      >
        <Flex gap='12px' align={'center'}>
          <Image src={PickLogo} w='24px' />
          <Text fontSize='14px' fontWeight='600' color='#7537FA'>
            {t('network_schedule.know_more_pick_hub')}
          </Text>
        </Flex>
        <Image src={ArrowUpIcon} />
      </Flex>
    )
  }
}

export default PickPromoBanner
