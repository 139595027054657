import { useTranslation } from 'react-i18next'
import { Flex, Text, Image, Link } from '@chakra-ui/react'
import { getTextColor } from '../../../../../../helpers/color'
import { Button, Column } from '@ubirider/pick-component-library'
import config from '../../../../../../config/configLoader'
import React from 'react'

import DownloadIcon from '../../../../../../assets/download.svg'
import bgMobile from '../../../../../../assets/fare_update_bus_mobile.png'
import bgDesktop from '../../../../../../assets/fare_update_bus_desktop.png'

export function MobiAzoresFareUpdatePage() {
  const { t } = useTranslation()

  return (
    <Flex direction='column' flex='1' w='100%' h='100%'>
      <Image display={{ sm: 'flex !important', lg: 'none !important' }} src={bgMobile} />
      <Flex flex='1' bg={config.themeTokens.colors.primary700}>
        <Flex
          w='100%'
          h={{ sm: '716px', lg: '100%' }}
          direction='column'
          padding={{ sm: '48px 24px', md: '48px 24px', lg: '88px 88px', xl: '88px 88px' }}
          gap={{ sm: '48px', md: '48px', lg: '64px', xl: '64px' }}
          overflow='hidden'
          justifyContent={{ lg: 'center' }}
        >
          <Flex alignItems='flex-start' direction='column' gap={{ sm: '24px', md: '24px', lg: '48px', xl: '48px' }}>
            <Column>
              <Text
                fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
                fontWeight='700'
                color={getTextColor()}
              >
                {t('promo.banner_fare_update_2025_title_1')}
              </Text>
              <Text
                textAlign='left'
                fontSize={{ sm: '24px', md: '24px', lg: '36px', xl: '36px' }}
                color={getTextColor()}
                mt='-8px'
              >
                2025
              </Text>
            </Column>
            <Text
              fontSize={{ sm: '18px', md: '18px', lg: '24px', xl: '24px' }}
              fontWeight={{ lg: 600 }}
              color={getTextColor()}
              textAlign='left'
            >
              {t('promo.banner_fare_update_2025_title_2')}
            </Text>

            <Button
              onClick={() =>
                window.open(
                  'https://ubirider-operator-websites-schedules.s3.eu-west-1.amazonaws.com/mobiazores/info/Anuncio+Aumento+prec%CC%A7os+2025.pdf',
                  '_blank'
                )
              }
              label={t('promo.banner_fare_update_2025_button')}
              type='neutralLight'
              style='solid'
              rightIconProps={{ src: DownloadIcon }}
            />
          </Flex>
        </Flex>

        <Image display={{ sm: 'none !important', lg: 'flex !important' }} src={bgDesktop} w='720px' />
      </Flex>
    </Flex>
  )
}
